import { useContext, useMemo } from 'react';
import ReactModal from 'react-modal';
import { RemoveScroll } from 'react-remove-scroll';

import Form from '../CoverageForm';
import CloseButton from './CloseButton';
import { Wrapper, Title, Subtitle, defaultStyle, defaultStyleMobile } from './styles';

import useBetterMediaQuery from '@/utils/useBetterMediaQuery';
import theme from '@/utils/styles-variables';
import ClientContext from '@/contexts/client.context';
import { products } from '@/utils/products';

const getProgramLabel = (product) => {
  switch (product.key) {
    case products.MOVE.key:
      return 'Move';
    case products.BLOOM.key:
      return 'Bloom';
    case products.ON_CALL.key:
      return 'On-call';
    case products.THRIVE.key:
      return 'Thrive';
    default:
      return 'Sword';
  }
};

export default function CoveredModal({ coveredModal }) {
  const { modalIsOpen, closeModal, client } = useContext(ClientContext);
  const { title, subtitle } = useMemo(
    () =>
      coveredModal || {
        title: 'Confirm your coverage',
        subtitle: `Verify if ${getProgramLabel(client.product)} is available for you`,
        buttonLabel: 'Get started',
      },
    [coveredModal, client.product],
  );

  const isMobile = useBetterMediaQuery(`(max-width: ${theme.breakpoints.max_sm}px)`);

  return (
    <RemoveScroll enabled={modalIsOpen} removeScrollBar={false}>
      <ReactModal
        style={isMobile ? defaultStyleMobile : defaultStyle}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
      >
        <Wrapper colored>
          <CloseButton className="close-button" closeModal={closeModal} />
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          <Form
            client={client}
            product={{ ...client.product, label: getProgramLabel(client.product) }}
            companiesPlaceholder={coveredModal?.companiesPlaceholder}
          />
        </Wrapper>
      </ReactModal>
    </RemoveScroll>
  );
}
