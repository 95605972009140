import { useContext } from 'react';
import styled from 'styled-components';

import { Container } from '@/components/ui/flexbox';
import ClientContext from '@/contexts/client.context';
import EnrollButton from '@/app/components/core/EnrollButton';
import { products } from '@/utils/products';
import Section from '@/components/ui/Section';

const EnrollCTA = ({ content }) => {
  const { client } = useContext(ClientContext);

  if (!content) {
    return null;
  }

  const { buttonLabel, message } = content;

  return (
    <Section id="section-enroll-cta">
      <StyledContainer id="enroll-cta" data-testid="enroll-cta">
        <Wrapper>
          {message && <Message>{message}</Message>}
          <EnrollButton
            isActive={client.isActive}
            buttonLocation={message}
            client={client}
            buttonLabel={buttonLabel}
            product={products[client?.product?.key?.toUpperCase()]}
            triage={client?.hasTriage}
            redirectToApp={client?.redirectToApp}
          />
        </Wrapper>
      </StyledContainer>
    </Section>
  );
};

const StyledContainer = styled(Container)`
  max-width: 833px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    max-width: 536px;
  }
`;

const Wrapper = styled.div`
  text-align: center;
`;

const Message = styled.p`
  font-family: ${(props) => props.theme.typography.family.title};
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 45px;
  line-height: 56px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export default EnrollCTA;
