import {
  ExpertCare,
  WhatTreats,
  ResultsYouCanFeel,
  EverythingYouNeed,
  GettingStarted,
  Testimonials,
  Movement,
  GridCards,
  Cta,
  SideSlider,
  StickyPanel,
} from './index';
import { HighlightedCTA } from '../shared';
import WhatIsSection from './WhatIsSection';
import EnrollCTA from 'components/client/EnrollCTA';

const COMPONENTS = {
  'dpt.we-treat': WhatTreats,
  'dpt.expert-care': ExpertCare,
  'dpt.results': ResultsYouCanFeel,
  'dpt.everything': EverythingYouNeed,
  'dpt.getting-started': GettingStarted,
  'dpt.testimonials': Testimonials,
  'sections.welcome-unit': WhatIsSection,
  'shared.movement': Movement,
  'shared.cta': HighlightedCTA,
  'shared.grid-cards': GridCards,
  'sections.get-started': EnrollCTA,
  'platform.cta': Cta,
  'platform.side-slider': SideSlider,
  'sections.sticky-panel': StickyPanel,
};

const getComponent = (componentKey) => {
  if (!COMPONENTS[componentKey]) {
    console.error(`Component ${componentKey} doesn't exist.`);
    return false;
  }

  return COMPONENTS[componentKey];
};

export default function DynamicDPTComponent({ body, isActive = true, ...props }) {
  if (!body) {
    console.error("Please provide a 'body' property to the DynamicComponent");
    return;
  }

  return (
    <div id="dynamic-zone">
      {body.map((bodyItem) => {
        const Component = getComponent(bodyItem['__component']);
        if (!Component) {
          return '';
        }

        return (
          <Component
            key={`${bodyItem['__component']}-${bodyItem.id}`}
            content={bodyItem}
            isActive={isActive}
            {...bodyItem}
            {...props}
          />
        );
      })}
    </div>
  );
}
