'use client';

import { useCallback, useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useSearchParams, useRouter } from 'next/navigation';
import styled, { css } from 'styled-components';

import { baseStyles } from './CompaniesDropdown/dropdown.styles';

import { Dropdown } from './Dropdown';
import { CompaniesDropdown } from './CompaniesDropdown';
import { PrimaryButton } from '@/components/ui/Button';
import { getClient, searchClient } from '@/services/client.service';
import { debounce } from '@/utils/debounce';
import getCountry from '@/utils/get-country';

import { createGuardaLink, getOnboardingURL } from '@/utils/onboarding-urls';

import {
  ACTIONS,
  BUTTON_TYPES,
  trackButtonClicked,
  trackClientEligibilityChecked,
} from '@/utils/track';

const SUBMIT_LABEL = 'Get Started';

export default function FormComponent({ product, client, companiesPlaceholder }) {
  const searchParams = useSearchParams();
  const [isNotCovered, setIsNotCovered] = useState(false);
  const [countries, setCountries] = useState(null);
  const [focusSelect, setFocusSelect] = useState(false);
  const selectClient = useRef(null);
  const router = useRouter();

  useEffect(() => {
    if (focusSelect) {
      selectClient?.current?.focus();
      setFocusSelect(false);
    }
  }, [focusSelect]);

  const formik = useFormik({
    initialValues: {
      client: client.clientKey ? client : null,
      country: null,
    },
    onSubmit: async (values, actions) => {
      if (!values.client || ((client.countries || countries) && !values.country)) {
        selectClient?.current?.focus();
        return;
      }

      if (values.client && !isNotCovered) {
        let fetchedClient = values.client;

        if (!fetchedClient.clientKey) {
          fetchedClient = await getClient({
            client: values.client.key,
            productKey: product.key,
          });
        }

        const url = client?.redirectToApp
          ? createGuardaLink({
              clientKey: fetchedClient.clientKey,
              programType: product?.param,
              serviceApp: product?.app,
            })
          : getOnboardingURL({
              client: fetchedClient,
              country: values?.country,
              programType: product?.param,
              query: Object.fromEntries(searchParams),
            });

        trackClientEligibilityChecked({
          clientExists: true,
          clientCountry: values?.country?.key,
          clientId: fetchedClient.clientKey,
          clientName: fetchedClient.name,
        });

        trackButtonClicked({
          action: client?.redirectToApp ? ACTIONS.REDIRECT_TO_APP : ACTIONS.REDIRECT_TO_ONBOARDING,
          buttonType: BUTTON_TYPES.NAVIGATION,
          buttonText: SUBMIT_LABEL,
          destination: url,
          buttonLocation: 'form',
        });

        actions.resetForm({
          values: {
            client: null,
            country: null,
          },
        });

        setCountries(null);
        window.location.assign(url);
        return;
      }

      trackClientEligibilityChecked({
        clientExists: false,
        clientCountry: null,
        clientId: values.client.key,
        clientName: values.client.label,
      });

      setIsNotCovered(true);

      actions.resetForm({
        values: {
          client: null,
          country: null,
        },
      });

      router.push(`/not-covered?client=${values.client.key}&program=${product?.key}`);
    },
  });

  const validateClient = useCallback(
    async (selectedClient, formik) => {
      formik.setStatus({ validating: true });
      setCountries(null);

      try {
        const fetchedClient = await getClient({
          client: selectedClient.key,
          productKey: product.key,
        });

        if (fetchedClient?.countries?.length > 1) {
          setCountries(fetchedClient.countries);
        }
      } catch {
        setIsNotCovered(true);
      } finally {
        formik.setStatus({ validating: false });
      }
    },
    [product],
  );

  const loadOptions = useCallback(
    (query, callback) => {
      searchClient({ query, ...product }).then((results) => {
        if (results.length === 0) {
          callback([{ key: query, label: query }]);
        } else {
          callback(
            results.map((result) => {
              return {
                key: result.clientKey || '',
                label: result.name,
              };
            }),
          );
        }
      });
    },
    [product],
  );

  const handleReset = () => {
    formik.resetForm();
    setCountries(null);
  };

  if (client?.clientKey) {
    return (
      <Form data-testid="onboarding-form" onSubmit={formik.handleSubmit}>
        <Field>
          <Dropdown
            name="country"
            placeholder="Select your region/country"
            options={client.countries.map((country) => getCountry(country.country))}
            noOptionsMessage={() => 'No results available'}
            value={formik.values.country}
            onChange={(value) => {
              formik.setFieldValue('country', value);
            }}
            customBaseStyles={baseStyles}
          />
        </Field>
        <PrimaryButton
          type="submit"
          loading={formik.status?.validating}
          disabled={formik.status?.validating}
        >
          {SUBMIT_LABEL}
        </PrimaryButton>
      </Form>
    );
  }

  return (
    <Form data-testid="onboarding-form" onSubmit={formik.handleSubmit}>
      <Field>
        <CompaniesDropdown
          innerRef={selectClient}
          name="companies"
          customBaseStyles={baseStyles}
          className="companiesDropdown"
          placeholder={companiesPlaceholder ?? 'Enter your employer or health plan'}
          loadOptions={debounce(loadOptions)}
          noOptionsMessage={() => null}
          value={formik.values.client}
          onChange={(value) => {
            formik.setValues({ client: value, country: null });
            validateClient(value, formik);
          }}
          error={formik.errors.client && formik.touched.client}
          errorLabel={'Not covered'}
          onFocus={() => {
            if (formik.values.client !== null) {
              setCountries(null);
              formik.setValues({ client: null, country: null });
            }
          }}
          handleReset={handleReset}
        />
      </Field>

      {countries && (
        <Field>
          <Dropdown
            name="country"
            placeholder="Select your region/country"
            options={countries.map((country) => getCountry(country.country))}
            noOptionsMessage={() => 'No results available'}
            value={formik.values.country}
            onChange={(value) => {
              formik.setFieldValue('country', value);
            }}
            customBaseStyles={baseStyles}
          />
        </Field>
      )}

      <PrimaryButton
        type="submit"
        loading={formik.status?.validating}
        disabled={formik.status?.validating}
      >
        {SUBMIT_LABEL}
      </PrimaryButton>
    </Form>
  );
}

const Form = styled.form`
  min-height: 128px;

  .companiesDropdown {
    background: white;
    box-shadow: 0 2px 4px rgba(31, 34, 44, 0.05), 0px 5px 10px rgba(31, 34, 44, 0.05);
    border-radius: 1000px;
    border: unset;
    outline: none !important;
  }
  ${(props) =>
    props.xl &&
    css`
      max-width: 700px;
      text-align: center;
      margin: auto;
    `};

  ${PrimaryButton} {
    font-family: ${(props) => props.theme.typography.family.title};
    font-size: 16px;
    line-height: 22px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    text-align: center;
    margin: auto;
  }
  button {
    padding: 12px 32px;
  }
`;

const Field = styled.div`
  position: relative;

  > [class*='-Input'] {
    margin-top: 30px;
  }
`;
