'use client';
import CoveredModal from '@/app/components/core/CoveredModal';

import { ContactsBanner, HighlightedCTA, FaqBanner, Faqs, Zendesk } from '@/app/components/shared';
import {
  AppSupport,
  Header,
  ExpertCare,
  WhatTreats,
  ResultsYouCanFeel,
  EverythingYouNeed,
  GettingStarted,
  Testimonials,
  Footer,
} from '@/app/_components/platform';
import DynamicPlatformComponent from './DynamicPlatformComponent';
import { ClientProvider } from '@/contexts/client.context';

const PlatformMainPage = ({ content, product }) => {
  const {
    contact,
    topBanner,
    header,
    dynamicPlaceholder,
    weTreat,
    expertCare,
    resultsList,
    testimonials,
    everything,
    gettingStarted,
    cta,
    faqs,
    faqBanner,
    app,
    bottomCTA,
    disclaimer,
    footnotes,
  } = content;

  return (
    <>
      <ClientProvider
        defaultClient={{
          clientKey: null,
          onboardingUrl: '',
          product,
          institutionKey: null,
          launchDate: null,
          isActive: true,
        }}
      >
        <CoveredModal />
        {contact && topBanner && <ContactsBanner {...contact} {...topBanner} />}
        {header && <Header content={header} />}
        {dynamicPlaceholder && <DynamicPlatformComponent body={dynamicPlaceholder} />}
        {weTreat && <WhatTreats content={weTreat} />}
        {expertCare && <ExpertCare content={expertCare} />}
        {resultsList && <ResultsYouCanFeel content={resultsList} />}
        {testimonials && <Testimonials content={testimonials} />}
        {everything && <EverythingYouNeed content={everything} />}
        {gettingStarted && <GettingStarted content={gettingStarted} />}
        {cta && <HighlightedCTA content={cta} />}
        {faqs && <Faqs content={faqs} />}
        {faqBanner && <FaqBanner content={faqBanner} contact={contact} />}
        {app && <AppSupport content={app} />}
        <Footer
          bottomCTA={bottomCTA}
          disclaimer={disclaimer}
          footnotes={footnotes}
          email={contact.email}
        />
      </ClientProvider>
      <Zendesk department={content?.zendeskDepartment} />
    </>
  );
};
export default PlatformMainPage;
