import styled from 'styled-components';
import Section from '@/components/ui/Section';
import { PrimaryButton } from '@/components/ui/Button';

export const Wrapper = styled(Section)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 106px 48px;
  border-radius: 16px;
  gap: 16px;
  overflow: visible;

  width: 100vw;
  max-width: 620px;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    ${PrimaryButton} {
      align-self: center;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding: 48px 24px;
    max-width: 100vw;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Title = styled.h5`
  font-family: ${(props) => props.theme.typography.family.title};
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Subtitle = styled.p`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
`;

export const defaultStyle = {
  overlay: {
    backgroundColor: 'rgba(31, 34, 44, 0.5)',
    zIndex: '100000',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    overflow: 'initial',
    backgroundColor: 'transparent',
    padding: '0',
  },
};

export const defaultStyleMobile = {
  overlay: {
    backgroundColor: 'rgba(31, 34, 44, 0.5)',
    zIndex: '100000',
  },
  content: {
    top: 'auto',
    left: '0',
    right: 'auto',
    bottom: '0',
    border: 'none',
    overflow: 'initial',
    backgroundColor: 'transparent',
    padding: '0',
  },
};
