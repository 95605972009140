import styled from 'styled-components';
import Close from '@/public/icons/circle-close.svg';

export default function CloseButton({ closeModal, ...rest }) {
  return (
    <Button type="button" onClick={closeModal} {...rest}>
      <Close />
    </Button>
  );
}

const Button = styled.button`
  position: absolute;
  content: '';
  right: 8px;
  top: 8px;
  padding: 12px 17.5px;
  cursor: pointer;
  z-index: 100001;

  svg {
    width: 20px;
    height: 20px;
    path {
      fill: ${({ theme }) => theme.colors.grey.dark};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    right: 0;
    top: -7px;
  }
`;
