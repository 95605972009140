import { getImgData } from '@/utils/images';
import { Card, CardDesc, CardTitle, ImageWrapper, Wrapper } from './styles';

const CustomCard = ({ title, description, cards, image }) => {
  const imageData = getImgData(image);

  return (
    <Card $cards={cards}>
      <ImageWrapper>
        <img src={imageData?.url} alt={imageData?.alt} />
      </ImageWrapper>
      <Wrapper>
        <CardTitle>{title}</CardTitle>
        <CardDesc>{description}</CardDesc>
      </Wrapper>
    </Card>
  );
};

export default CustomCard;
