import { Container } from '@/components/ui/flexbox';
import { CustomSection, Subtitle, Title, Grid } from './styles';

import CustomCard from './Card';

const WhatIsSection = ({ content, hasSection = false }) => {
  const { title, subtitle, cards } = content;

  return (
    <CustomSection $hasSection={hasSection} spacerTop>
      <Container large>
        <Title variant="3">{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Grid>
          {cards?.map((item) => (
            <CustomCard key={item.id} {...item} cards={cards.length} />
          ))}
        </Grid>
      </Container>
    </CustomSection>
  );
};

export default WhatIsSection;
